.datepicker {
	body & {
		color: var(--#{$prefix}component-color);
		border-radius: $border-radius-lg;
		
		@if $enable-rtl {
			direction: rtl;
		}
		
		& table {
			width: 100%;
		}
		& .datepicker-days,
		& .datepicker-months,
		& .datepicker-years,
		& .datepicker-decades,
		& .datepicker-centuries {
			& table {
				& thead {
					& tr {
						& th {
							color: var(--#{$prefix}component-color);
							
							&.prev,
							&.next,
							&.datepicker-switch {
								line-height: $line-height-base;
								padding: $spacer * .5;
								border-radius: $border-radius;
								
								&:hover,
								&:focus {
									background: var(--#{$prefix}component-hover-bg);
									
									&:before {
										color: var(--#{$prefix}component-color);
									}
								}
							}
							&.prev,
							&.next {
								color: transparent;
								width: 15%;
								position: relative;
								
								@include fontawesome();
								
								&:before {
									color: $gray-500;
									position: absolute;
									font-size: $font-size-lg;
									height: $font-size-lg;
									top: 50%;
									margin-top: -$font-size-lg * .5;
									line-height: 1;
								}
								& i {
									display: none;
								}
							}
							&.prev {
								&:before {
									content: '\f104';
								}
							}
							&.next {
								&:before {
									content: '\f105';
								}
							}
							&.datepicker-switch {
								width: auto;
							}
							&.dow {
								padding: $spacer * .5 0;
							}
						}
					}
				}
				& tbody {
					& tr {
						& td {
							padding: $spacer * .5;
							
							& span {
								&.decade,
								&.year,
								&.month,
								&.century {
									font-weight: $font-weight-semibold;
									color: $gray-600;
									text-shadow: none;
									border-radius: $border-radius;
									
									&:hover,
									&:focus {
										background: var(--#{$prefix}component-hover-bg);
									}
									&.focused {
										background: var(--#{$prefix}component-hover-bg);
										color: var(--#{$prefix}component-hover-color);
									}
									&.active {
										background: var(--#{$prefix}component-active-bg) !important;
										color: var(--#{$prefix}component-active-color);
									}
								}
							}
							&.day {
								font-weight: $font-weight-semibold;
								color: rgba(var(--#{$prefix}component-color-rgb), .75);
								padding: $spacer * .5;
								border-radius: $border-radius;
								
								&:hover,
								&:focus,
								&.selected,
								&.highlighted,
								&.range {
									background: var(--#{$prefix}component-hover-bg);
									text-shadow: none;
								}
								&.today {
									background: tint-color($component-active-bg, 75%) !important;
									color: shade-color($component-active-bg, 75%);
								}
								&.active {
									background: $component-active-bg !important;
									color: $white;
									text-shadow: none !important;
								}
								&.range {
									border-radius: 0;
								}
								&.range-start {
									border-radius: $border-radius 0 0 $border-radius;
								}
								&.range-end {
									border-radius: 0 $border-radius $border-radius 0;
								}
								&.old {
									color: var(--#{$prefix}component-disabled-color);
								}
								&.new {
									color: var(--#{$prefix}component-disabled-color);
								}
								&.disabled {
									color: var(--#{$prefix}component-disabled-color);
									
									&:hover,
									&:focus {
										background: none;
									}
								}
							}
						}
					}
				}
			}
		}
		
		&.datepicker-inline {
			display: block;
			width: auto;
			padding: 0;
		}
		&.dropdown-menu {
			min-width: rem(250px);
			width: rem(320px);
			border: none;
			font-family: inherit;
			font-size: $font-size-base;
			box-shadow: $box-shadow;
			
			@include media-breakpoint-down(xs) {
				width: rem(250px);
			}
			
			&:before {
				display: none;
			}
			&.datepicker-orient-left {
				&:after {
					left: $spacer;
				}
			}
			&.datepicker-orient-right {
				&:after {
					right: $spacer;
				}
			}
			&.datepicker-orient-top {
				margin-bottom: $spacer * .5;
			}
			&.datepicker-orient-bottom {
				margin-top: $spacer * .5;
			}
		}
	}
	& .input-daterange {
		& .input-group-addon {
			margin: 0;
			font-weight: $font-weight-semibold;
			background: var(--#{$prefix}component-border-color);
			color: var(--#{$prefix}component-color);
			display: flex;
			align-items: center;
			border-radius: 0;
			
			& + .form-control {
				border-radius: 0 $border-radius $border-radius 0;
				
				@if $enable-rtl {
					border-radius: 0;
				}
			}
		}
	}
	&.datepicker-dropdown {
		&:after{
			border-bottom-color: var(--#{$prefix}component-dropdown-bg);
		}
		&.datepicker-orient-top {
			&:after {
				border-top-color: var(--#{$prefix}component-dropdown-bg);
			}
		}
	}
}