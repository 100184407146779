.app-sidebar-float-submenu-container {
	position: fixed;
	width: $app-sidebar-width;
	margin: 0;
	background: var(--#{$prefix}app-sidebar-float-submenu-bg);
	z-index: $app-sidebar-float-submenu-zindex;
	border: 1px solid var(--#{$prefix}component-border-color-transparent);
	
	& .app-sidebar-float-submenu {
		@if $enable-rtl {
			padding: rem(9px) rem(39px) rem(9px) 0;
		} @else {
			padding: rem(9px) 0 rem(9px) rem(39px);
		}
		
		& .active,
		& .expanding,
		& .expand {
			& > a {
				& .menu-caret {
					&:before {
						opacity: 1.0;
						transform: rotate(90deg);
						
						@if $enable-rtl {
							transform: rotate(-90deg);
						}
					}
				}
			}
		}
		& .menu-caret {
			display: block;
			margin-left: auto;
			width: rem(20px);
			text-align: center;
			font-size: $font-size-sm;
			border: none;
		
			@include fontawesome();
			@if $enable-rtl {
				margin-right: auto;
			}
		
			&:before {
				content: '\f0da';
				display: block;
				text-align: center;
				opacity: 0.5;
				transition: all .2s linear;
				
				@if $enable-rtl {
					content: '\f0d9';
				}
			}
		}
		& .menu-item {
			position: relative;
					
			& .menu-link {
				padding: rem(4px) rem(20px) rem(4px) rem(15px);
				line-height: rem(20px);
				color: var(--#{$prefix}app-sidebar-menu-submenu-link-color);
				text-decoration: none;
				position: relative;
				display: flex;
				align-items: flex-start;
				font-weight: var(--#{$prefix}app-sidebar-menu-submenu-link-font-weight);
				
				@if $enable-rtl {
					padding-right: rem(15px);
					padding-left: rem(20px);
				}
				
				& .menu-text {
					flex: 1;
				}
				&:hover,
				&:focus {
					background: none;
					color: var(--#{$prefix}app-sidebar-menu-submenu-link-hover-color);
				}
				&:after {
					content: '';
					position: absolute;
					top: rem(11px);
					width: rem(11px);
					height: rem(2px);
					background: var(--#{$prefix}app-sidebar-float-submenu-grid-border-color);
		
					@if $enable-rtl {
						right: rem(-11px);
					} @else {
						left: rem(-11px);
					}
				}
			}
			&:before {
				content: '';
				position: absolute;
				left: rem(-13px);
				top: 0;
				bottom: 0;
				width: rem(2px);
				background: var(--#{$prefix}app-sidebar-float-submenu-grid-border-color);
	
				@if $enable-rtl {
					right: rem(-13px);
					left: auto;
				}
			}
			&:after {
				content: '';
				position: absolute;
				left: 0;
				width: rem(6px);
				height: rem(6px);
				border: var(--#{$prefix}app-sidebar-menu-grid-border-width) solid var(--#{$prefix}app-sidebar-menu-grid-border-color);
				top: rem(11px);
				margin-top: rem(-2px);
				z-index: 10;
				background: var(--#{$prefix}app-sidebar-bg);
				border-radius: rem(4px);
				
				@if $enable-rtl {
					left: auto;
					right: 0;
				}
			}
			&:first-child {
				&:before {
					top: rem(-11px);
				}
				&:last-child:before {
					height: rem(20px);
				}
			}
			&:last-child {
				&:before {
					bottom: auto;
					height: rem(13px);
				}
			}
			&.has-sub {
				& > a {
					&:before {
						background: var(--#{$prefix}app-sidebar-float-submenu-grid-border-color);
					}
				}
			}
			&.active {
				& > .menu-link {
					background: none;
					color: var(--#{$prefix}app-sidebar-component-color);
				}
				&:after {
					border-color: var(--#{$prefix}app-theme);
				}
			}
			
			& .menu-submenu {
				padding: 0 0 0 rem(15px);
				background: none;
				position: relative;
				display: none;

				@if $enable-rtl {
					padding-left: 0;
					padding-right: rem(15px);
				}
			}
			
			&.active {
				&.has-sub {
					& > .menu-submenu {
						display: block;
					}
				}
			}
		}
		& > .menu-item {
			&:first-child {
				&:before {
					top: rem(11px);
				}
				&:last-child {
					&:before {
						height: 0 !important;
					}
				}
			}
		}
	}
	& .app-sidebar-float-submenu-arrow {
		position: absolute;
		top: rem(20px);
		width: rem(28px);
		height: rem(2px);
		background: var(--#{$prefix}app-sidebar-float-submenu-grid-border-color);
		
		@if $enable-rtl {
			right: 0;
		} @else {
			left: 0;
		}
	}
	& .app-sidebar-float-submenu-line {
		position: absolute;
		top: rem(20px);
		width: rem(2px);
		background: var(--#{$prefix}app-sidebar-float-submenu-grid-border-color);
		
		@if $enable-rtl {
			right: rem(26px);
		} @else {
			left: rem(26px);
		}
	}
}