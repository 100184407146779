/* Estilos iniciales */
div > .stepper-inactive-step {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s, transform 0.5s;
  visibility: hidden; /* Hace que el componente no sea visible ni ocupando espacio */
  max-height: 0; /* Asegura que el componente no ocupe espacio */
  overflow: hidden; /* Oculta cualquier contenido que se desborde */
}

/* Estilos cuando el paso está activo */
.stepper-active-step {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  max-height: 1000px; /* Ajusta según tus necesidades, debería ser lo suficientemente alto */
}

.app-content {
  /* margin-left: 0px !important; */
  margin-left: 0px;
}
