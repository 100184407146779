body {
	& .daterangepicker {
		z-index: 1020;
		border: none;
		font-family: inherit;
		background-color: var(--#{$prefix}component-dropdown-bg);
		border-radius: $border-radius-lg;
		box-shadow: $box-shadow;
		
		@if $enable-rtl {
			direction: rtl;
			text-align: right;
		}
		@include media-breakpoint-down(md) {
			width: rem(300px);
		}
		
		&:before {
			display: none;
		}
		&:after {
			border-bottom-color: var(--#{$prefix}component-dropdown-bg);
		}
		
		& .drp-calendar {
			max-width: inherit;
			padding: $spacer;
			
			@include media-breakpoint-down(xl) {
				float: none;
			}
			
			& .calendar-table {
				background-color: var(--#{$prefix}component-dropdown-bg);
				border-color: var(--#{$prefix}component-dropdown-bg);
				
				& table {
					& thead {
						& tr {
							& th {
								min-width: auto;
								width: auto;
								height: auto;
								line-height: 1;
								padding: $spacer * .5;
								color: var(--#{$prefix}component-color);
								font-weight: $font-weight-semibold;
								border: none;
								
								&.prev,
								&.next {
									& span {
										border-color: var(--#{$prefix}component-color);
									}
									&:hover,
									&:focus {
										background: #{ rgba(var(--#{$prefix}component-color-rgb), .2)};
										color: var(--#{$prefix}component-color);
									}
								}
								&.month {
									& .yearselect,
									& .monthselect {
										border-color: var(--#{$prefix}component-dropdown-border-color);
										background: var(--#{$prefix}component-dropdown-bg);
										color: var(--#{$prefix}component-color);
										border-radius: $border-radius;
										transition: all .2s linear;
										
										&:focus {
											outline: none;
											border-color: $input-focus-border-color;
											box-shadow: $input-focus-box-shadow;
										}
									}
								}
							}
							&:last-child {
								& th {
									padding-bottom: $spacer * .5;
								}
							}
						}
					}
					& tbody {
						& tr {
							& td {
								min-width: auto;
								width: auto;
								height: auto;
								line-height: 1;
								padding: $spacer * 0.65;
								font-weight: $font-weight-semibold;
								color: var(--#{$prefix}component-color);
								border: none;
								
								&.available {
									border-radius: $border-radius;
									
									&:hover,
									&:focus {
										background: var(--#{$prefix}component-dropdown-hover-bg);
									}
									&.in-range {
										background: tint-color($component-active-bg, 85%);
										color: var(--#{$prefix}component-color);
										border-radius: 0;
										
										.dark-mode & {
											background: shade-color($component-active-bg, 50%);
											
											&.active {
												background: $component-active-bg;
											}
										}
									}
									&.active {
										background: $component-active-bg;
										color: $white;
									}
									&.start-date {
										border-radius: $border-radius 0 0 $border-radius;
									}
									&.end-date {
										border-radius: 0 $border-radius $border-radius 0;
									}
								}
								&.off {
									background: none;
									color: var(--#{$prefix}component-disabled-color);
								}
							}
						}
					}
				}
			}
			&.left {
				padding: $spacer * .5 !important;
				
				& .calendar-table {
					padding: 0 !important;
				}
			}
			&.right {
				padding: $spacer * .5 !important;
				border-left: 1px solid var(--#{$prefix}component-dropdown-border-color);
				
				@include media-breakpoint-down(xl) {
					border-left: none;
					border-top: 1px solid var(--#{$prefix}component-dropdown-border-color);
				}
			}
		}
		& .drp-buttons {
			padding: $spacer * .5;
			border-color: var(--#{$prefix}component-dropdown-border-color);
			
			@if $enable-rtl {
				text-align: left;
			}
			@include media-breakpoint-down(xl) {
				text-align: center;
			}
			
			& .btn {
				font-weight: $font-weight-semibold;
				min-width: rem(80px);
				margin: 0;
				
				& + .btn {
					@if $enable-rtl {
						margin-left: 0;
						margin-right: $spacer * .5;
					} @else {
						margin-right: 0;
						margin-left: $spacer * .5;
					}
				}
			}
			& .drp-selected {
				font-weight: $font-weight-semibold;
				color: $gray-600;
				
				@if $enable-rtl {
					margin-right: 0;
					margin-left: $spacer * .5;
				}
				@include media-breakpoint-down(xl) {
					display: block;
					padding: 0;
					margin-bottom: $spacer * .5;
				}
			}
		}
		&.show-ranges.ltr,
		&.show-ranges.rtl {
			& .ranges {
				position: relative;
				display: block;
				
				@if $enable-rtl {
					float: right;
				}
				@include media-breakpoint-down(xl) {
					float: none;
					margin: 0;
					padding: rem(10px);
					border-bottom: 1px solid var(--#{$prefix}component-dropdown-border-color);
					overflow: scroll;
					max-width: rem(340px);
					
					@if $enable-rtl {
						float: none;
					}
				}
				
				& ul {
					@include media-breakpoint-down(xl) {
						width: auto;
						white-space: nowrap;
						padding-right: $spacer;
						display: flex;
						flex-wrap: nowrap;
					}
					
					& li {
						font-weight: $font-weight-semibold;
						color: rgba(var(--#{$prefix}component-color-rgb), .75);
						
						@include media-breakpoint-down(xl) {
							margin-right: $spacer !important;
							border-radius: $border-radius;
						}
						
						&:hover,
						&:focus {
							background: var(--#{$prefix}component-dropdown-hover-bg);
							color: var(--#{$prefix}component-hover-color);
						}
						&.active {
							background: $component-active-bg;
							color: $white;
							margin-right: rem(-1px);
						}
					}
				}
			}
			& .drp-calendar {
				&.left { 
					border-left: 1px solid var(--#{$prefix}component-dropdown-border-color);
					
					@if $enable-rtl {
						border-left: none !important;
					}
					@include media-breakpoint-down(xl) {
						border-left: none;
					}
				}
				&.right { 
					@if $enable-rtl {
						border-right: 1px solid var(--#{$prefix}component-dropdown-border-color);
					}
					@include media-breakpoint-down(xl) {
						@if $enable-rtl {
							border-right: none;
						}
					}
				}
			}
		}
	}
}