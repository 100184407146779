@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700";
@import "https://cdnjs.cloudflare.com/ajax/libs/nvd3/1.8.1/nv.d3.min.css";
@import "https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css";
body,
html {
  height: 100%;
}

.h-100 {
  height: 100%;
}

.flex-grow-1 {
  flex-grow: 1;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}


.textoresponsive {
  text-align: end;
  color: gray;
}

/* Estilo para pantallas pequeñas (por ejemplo, menos de 768px) */
@media screen and (max-width: 991px) {
  .textoresponsive {
    text-align: left;
  }
}
