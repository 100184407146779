div > .stepper-inactive-step {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s, transform 0.5s;
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
}

/* Estilos cuando el paso está activo */
.stepper-active-step {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  max-height: 1000px;
}
