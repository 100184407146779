.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #bad9f100 !important;
  /* background-color: #158fee40 !important; */
}

.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--selected {
  background-color: rgba(21, 144, 238, 0.824) !important;
}
