main {
  margin: 0;
  overflow: hidden;
}

.drawing {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: block;
  background-color: #ffffff;
}

/* -- Loading thingy --*/
.loading-dot {
  border-radius: 100%;
  height: 10px;
  width: 10px;
  background-color: #e40050;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
    40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
    40px 40px 0px 0px #e40050, -40px -40px 0px 0px #e40050,
    40px -40px 0px 0px #e40050, -40px 40px 0px 0px #e40050;
  -webkit-animation: load 3s infinite linear;
  -moz-animation: load 3s infinite linear;
  -o-animation: load 3s infinite linear;
  animation: load 3s infinite linear;
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: translate(0px, 0px), rotate(0deg);
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      40px 40px 0px 0px #e40050, -40px -40px 0px 0px #e40050,
      40px -40px 0px 0px #e40050, -40px 40px 0px 0px #e40050;
  }

  25% {
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      -40px -40px 0px 0px #e40050, 40px 40px 0px 0px #e40050,
      -40px 40px 0px 0px #e40050, 40px -40px 0px 0px #e40050;
  }

  50% {
    box-shadow: 0px -40px 0px 0px #e40050, 0px 40px 0px 0px #e40050,
      -40px 0px 0px 0px #e40050, 40px 0px 0px 0px #e40050,
      -40px -40px 0px 0px #e40050, 40px 40px 0px 0px #e40050,
      -40px 40px 0px 0px #e40050, 40px -40px 0px 0px #e40050;
  }

  70% {
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
  }

  75% {
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    height: 10px;
    width: 10px;
  }

  80% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    background-color: #e40050;
    height: 40px;
    width: 40px;
  }

  85% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    background-color: #e40050;
    height: 40px;
    width: 40px;
  }

  90% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    background-color: #e40050;
    height: 10px;
    width: 10px;
  }

  95% {
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      40px 40px 0px 0px #e40050, -40px -40px 0px 0px #e40050,
      40px -40px 0px 0px #e40050, -40px 40px 0px 0px #e40050;
  }

  100% {
    -webkit-transform: rotate(360deg);
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      40px 40px 0px 0px #e40050, -40px -40px 0px 0px #e40050,
      40px -40px 0px 0px #e40050, -40px 40px 0px 0px #e40050;
  }
}

@-moz-keyframes load {
  0% {
    -moz-transform: translate(0px, 0px), rotate(0deg);
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      40px 40px 0px 0px #e40050, -40px -40px 0px 0px #e40050,
      40px -40px 0px 0px #e40050, -40px 40px 0px 0px #e40050;
  }

  25% {
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      -40px -40px 0px 0px #e40050, 40px 40px 0px 0px #e40050,
      -40px 40px 0px 0px #e40050, 40px -40px 0px 0px #e40050;
  }

  50% {
    box-shadow: 0px -40px 0px 0px #e40050, 0px 40px 0px 0px #e40050,
      -40px 0px 0px 0px #e40050, 40px 0px 0px 0px #e40050,
      -40px -40px 0px 0px #e40050, 40px 40px 0px 0px #e40050,
      -40px 40px 0px 0px #e40050, 40px -40px 0px 0px #e40050;
  }

  70% {
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
  }

  75% {
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    height: 10px;
    width: 10px;
  }

  80% {
    -moz-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    background-color: #e40050;
    height: 40px;
    width: 40px;
  }

  85% {
    -moz-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    background-color: #e40050;
    height: 40px;
    width: 40px;
  }

  90% {
    -moz-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    background-color: #e40050;
    height: 10px;
    width: 10px;
  }

  95% {
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      40px 40px 0px 0px #e40050, -40px -40px 0px 0px #e40050,
      40px -40px 0px 0px #e40050, -40px 40px 0px 0px #e40050;
  }

  100% {
    -moz-transform: rotate(360deg);
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      40px 40px 0px 0px #e40050, -40px -40px 0px 0px #e40050,
      40px -40px 0px 0px #e40050, -40px 40px 0px 0px #e40050;
  }
}

@keyframes load {
  0% {
    transform: translate(0px, 0px), rotate(0deg);
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      40px 40px 0px 0px #e40050, -40px -40px 0px 0px #e40050,
      40px -40px 0px 0px #e40050, -40px 40px 0px 0px #e40050;
  }

  25% {
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      -40px -40px 0px 0px #e40050, 40px 40px 0px 0px #e40050,
      -40px 40px 0px 0px #e40050, 40px -40px 0px 0px #e40050;
  }

  50% {
    box-shadow: 0px -40px 0px 0px #e40050, 0px 40px 0px 0px #e40050,
      -40px 0px 0px 0px #e40050, 40px 0px 0px 0px #e40050,
      -40px -40px 0px 0px #e40050, 40px 40px 0px 0px #e40050,
      -40px 40px 0px 0px #e40050, 40px -40px 0px 0px #e40050;
  }

  70% {
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
  }

  75% {
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    height: 10px;
    width: 10px;
  }

  80% {
    transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    background-color: #e40050;
    height: 40px;
    width: 40px;
  }

  85% {
    transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    background-color: #e40050;
    height: 40px;
    width: 40px;
  }

  90% {
    transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    background-color: #e40050;
    height: 10px;
    width: 10px;
  }

  95% {
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      40px 40px 0px 0px #e40050, -40px -40px 0px 0px #e40050,
      40px -40px 0px 0px #e40050, -40px 40px 0px 0px #e40050;
  }

  100% {
    transform: rotate(360deg);
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      40px 40px 0px 0px #e40050, -40px -40px 0px 0px #e40050,
      40px -40px 0px 0px #e40050, -40px 40px 0px 0px #e40050;
  }
}
@-o-keyframes load {
  0% {
    -o-transforms: translate(0px, 0px), rotate(0deg);
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      40px 40px 0px 0px #e40050, -40px -40px 0px 0px #e40050,
      40px -40px 0px 0px #e40050, -40px 40px 0px 0px #e40050;
  }

  25% {
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      -40px -40px 0px 0px #e40050, 40px 40px 0px 0px #e40050,
      -40px 40px 0px 0px #e40050, 40px -40px 0px 0px #e40050;
  }

  50% {
    box-shadow: 0px -40px 0px 0px #e40050, 0px 40px 0px 0px #e40050,
      -40px 0px 0px 0px #e40050, 40px 0px 0px 0px #e40050,
      -40px -40px 0px 0px #e40050, 40px 40px 0px 0px #e40050,
      -40px 40px 0px 0px #e40050, 40px -40px 0px 0px #e40050;
  }

  70% {
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
  }

  75% {
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    height: 10px;
    width: 10px;
  }

  80% {
    -o-transforms: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    background-color: #e40050;
    height: 40px;
    width: 40px;
  }

  85% {
    -o-transforms: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    background-color: #e40050;
    height: 40px;
    width: 40px;
  }

  90% {
    -o-transforms: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050,
      0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050, 0px 0px 0px 0px #e40050;
    background-color: #e40050;
    height: 10px;
    width: 10px;
  }

  95% {
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      40px 40px 0px 0px #e40050, -40px -40px 0px 0px #e40050,
      40px -40px 0px 0px #e40050, -40px 40px 0px 0px #e40050;
  }

  100% {
    -o-transforms: rotate(360deg);
    box-shadow: 0px 40px 0px 0px #e40050, 0px -40px 0px 0px #e40050,
      40px 0px 0px 0px #e40050, -40px 0px 0px 0px #e40050,
      40px 40px 0px 0px #e40050, -40px -40px 0px 0px #e40050,
      40px -40px 0px 0px #e40050, -40px 40px 0px 0px #e40050;
  }
}
